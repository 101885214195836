import { AnyAction } from 'redux';
import Status from 'types/requests';
import { READ_SUCCESS as ACCOUNT_READ_SUCCESS } from '../Account/constants';
import { ConnectAccount } from '../Account/types';
import constants from './constants';
import type { EmoneyAddressReduxStore } from './types';

const initial: EmoneyAddressReduxStore = {
  isListRequest: false,
  isListSuccess: false,
  isListFailure: undefined,
  list: [],
  isCreateRequest: false,
  isCreateSuccess: false,
  isCreateFailure: undefined,
  isSelectRequest: false,
  isSelectSuccess: false,
  isSelectFailure: undefined,
  selected: undefined,
  isLinkRequest: false,
  isLinkSuccess: false,
  isLinkMultiSigPending: false,
  isLinkFailure: undefined,
  safeTxHash: '',
  linkAccounts: [] as ConnectAccount[],
  linkDataStatus: Status.IDLE,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.LIST_REQUEST: {
      return { ...state, isListRequest: true };
    }
    case constants.LIST_SUCCESS: {
      const { addresses } = action;
      return {
        ...state,
        list: addresses,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
        error: undefined,
      };
    }
    case constants.LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isListRequest: false,
        isListSuccess: false,
        isListFailure: error,
        error,
      };
    }
    case constants.CREATE_REQUEST: {
      return { ...state, isCreateRequest: true };
    }
    case constants.CREATE_SUCCESS: {
      const { address } = action;
      return {
        ...state,
        list: [...state.list, address],
        isCreateRequest: false,
        isCreateSuccess: true,
        isCreateFailure: undefined,
        error: undefined,
      };
    }
    case constants.CREATE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreateRequest: false,
        isCreateSuccess: false,
        isCreateFailure: error,
        error,
      };
    }
    case constants.CREATE_RESET: {
      return {
        ...state,
        isCreateRequest: false,
        isCreateSuccess: false,
        isCreateFailure: undefined,
        error: undefined,
      };
    }

    case constants.SELECT_SUCCESS: {
      const { address } = action;
      return {
        ...state,
        selected: address,
        isSelectRequest: false,
        isSelectSuccess: true,
        isSelectFailure: undefined,
        isLinkRequest: false,
        isLinkSuccess: false,
        isLinkFailure: undefined,
        error: undefined,
      };
    }
    case ACCOUNT_READ_SUCCESS: {
      const { item: account } = action;

      return {
        ...state,
        selected: account?.address,
        isSelectRequest: false,
        isSelectSuccess: true,
        isSelectFailure: undefined,
        isLinkRequest: false,
        isLinkSuccess: false,
        isLinkFailure: undefined,
        error: undefined,
      };
    }

    case constants.LINK_REQUEST: {
      return {
        ...state,
        isLinkRequest: true,
        isLinkMultiSigPending: false,
        isLinkFailure: undefined,
        error: undefined,
      };
    }
    case constants.LINK_SUCCESS: {
      return {
        ...state,
        isLinkRequest: false,
        isLinkSuccess: true,
        isLinkFailure: undefined,
        error: undefined,
      };
    }
    case constants.LINK_MULTI_SIG_PENDING: {
      const { safeTxHash } = action;
      return {
        ...state,
        isLinkRequest: false,
        isLinkMultiSigPending: true,
        isLinkFailure: undefined,
        safeTxHash,
        error: undefined,
      };
    }
    case constants.LINK_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isLinkRequest: false,
        isLinkSuccess: false,
        isLinkFailure: error,
        error,
      };
    }

    case constants.LINK_RESET: {
      return {
        ...state,
        isLinkRequest: false,
        isLinkSuccess: false,
        isLinkMultiSigPending: false,
        isLinkFailure: undefined,
        error: undefined,
      };
    }

    case constants.VISIBILITY_REQUEST: {
      return {
        ...state,
        linkDataStatus: Status.PENDING,
      };
    }
    case constants.VISIBILITY_SUCCESS: {
      return {
        ...state,
        linkDataStatus: Status.FULFILLED,
        linkAccounts: action.payload,
      };
    }

    default:
      return state;
  }
};
