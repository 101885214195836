export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_ERROR_UNKNOWN = 'Sorry! Something went wrong.';
export const ALERT_ERROR_CONNECTION_REFUSED =
  'Could not connect with server, please try reloading the page.';
export const ALERT_WARNING = 'ALERT_WARNING';
export const ALERT_INFO = 'ALERT_INFO';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_ADD = 'ALERT_ADD';
export const ALERT_CLOSE = 'ALERT_CLOSE';

export default {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_ERROR_UNKNOWN,
  ALERT_ERROR_CONNECTION_REFUSED,
  ALERT_WARNING,
  ALERT_INFO,
  ALERT_CLEAR,
  ALERT_ADD,
  ALERT_CLOSE,
};
