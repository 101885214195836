// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
export const insertCss = (...styles: IsomorphicStyleLoaderStyle[]) => {
  const removeCss = styles.map((x) =>
    // eslint-disable-next-line no-underscore-dangle
    typeof x._insertCss === 'function' ? x._insertCss() : undefined,
  );
  return () => {
    removeCss.forEach((f) => {
      if (f) f();
    });
  };
};

/**
 * Fetch a CSS Global variable to use in JS/TS
 * @param {string} cssVariable - The name of the CSS variable to be use i.e. --my-var.
 *
 * Example:
 * <div style={{ background: cssVar('--brand-color-blue') }} />
 */

export const cssVar = (cssVariable: string) =>
  getComputedStyle(document.body).getPropertyValue(cssVariable);

/**
 * Create a dynamic css variable in JS/TS to use in css.
 * @param cssVariable The name of the CSS variable to be set, i.e. --my-var
 * @param value The value the CSS variable should hold, i.e. '300px'
 *
 * Example:
 *  <div className="parent" style={declareCssVar('--padding', `${someVariable}px}`)}>
 *    <div className="child" />
 *  </div>
 *
 * component.less:
 *
 * .child {
 *    padding: var(--padding);
 * }
 */

export const declareCssVar = (cssVariable: string, value: string) => {
  return { [cssVariable]: value };
};

/**
 * Create a dynamic global variable in JS/TS to use in css.
 * @param cssVariable The name of the CSS variable to be set, i.e. --my-var
 * @param value The value the CSS variable should hold, i.e. '300px'
 *
 * Example:
 *  declareGlobalCssVar('--padding', `${someVariable}px}`);
 *
 * Any stylesheet:
 *
 * .component {
 *    padding: var(--padding);
 * }
 */
export const declareGlobalCssVar = (cssVariable: string, value: string) => {
  document.documentElement.style.setProperty(cssVariable, value);
};

export default insertCss;
