import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useAccount } from 'wagmi';
import { ReduxStore, useAppSelector } from 'src/customer/store/configureStore';
import type { Chain } from 'src/types/emoney/Chain';
import { getDefaultChains } from 'src/utils/emoney/chains';
import { Account } from '../Account/types';

interface UseChain {
  chain: Chain;
  chains: Chain[];
  selected: Chain;
  getChainByMoneriumId: (id: string) => Chain | undefined;
}

const accountSelector = (state: ReduxStore) => state.emoney?.account;

const selectedAccountSelector = createSelector(
  accountSelector,
  (account) => account?.selected || ({} as Account),
);

const getSelectedChain = (): Chain | undefined => {
  const activeAccount = useAppSelector(selectedAccountSelector, shallowEqual);
  const supported = getDefaultChains();
  const selectedChain = supported?.find(
    (c: Chain) => c?.moneriumId?.chain === activeAccount?.chain,
  );

  return selectedChain;
};

const useChain = (): UseChain => {
  const chains = getDefaultChains();

  const { chain } = useAccount();

  /** selected chain depends on which account the user is viewing */
  const selected = getSelectedChain();

  const getChainByMoneriumId = useCallback(
    (moneriumChainId: string) => {
      return chains?.find((c) => c?.moneriumId?.chain === moneriumChainId);
    },
    [chains],
  );

  return {
    // currently connected in the Wallet
    chain,
    // all chains configured in the app
    chains,
    // currently selected in the interface
    selected: selected as Chain,
    getChainByMoneriumId: useCallback(
      (moneriumChainId) => getChainByMoneriumId(moneriumChainId),
      [getChainByMoneriumId, chains],
    ),
  };
};

export default useChain;
