import { AnyAction } from 'redux';
import constants from './constants';
import { BlockReduxStore } from './types';

const initial: BlockReduxStore = {
  height: 0,
  ws: undefined,
  isConnectRequest: false,
  isConnectSuccess: false,
  isConnectFailure: false,
  isDisconnectRequest: false,
  isDisconnectSuccess: false,
  isDisconnectFailure: false,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.MESSAGE_SUCCESS: {
      const { payload } = action;
      const { data } = payload;

      return { ...state, height: parseInt(data, 10) };
    }

    case constants.CONNECT_REQUEST: {
      return { ...state, isConnectRequest: true };
    }
    case constants.CONNECT_SUCCESS: {
      const { ws } = action;
      return {
        ...state,
        ws,
        isConnectRequest: false,
        isConnectSuccess: true,
        isDisconnectSuccess: false,
        isConnectFailure: false,
      };
    }
    case constants.CONNECT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isConnectRequest: false,
        isConnectSuccess: false,
        isConnectFailure: true,
      };
    }

    case constants.DISCONNECT_REQUEST: {
      return { ...state, isDisconnectRequest: true };
    }
    case constants.DISCONNECT_SUCCESS: {
      return {
        ...state,
        ws: null,
        isDisconnectRequest: false,
        isDisconnectSuccess: true,
        isConnectSuccess: false,
        isDisconnectFailure: false,
      };
    }
    case constants.DISCONNECT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isDisconnectRequest: false,
        isDisconnectSuccess: false,
        isDisconnectFailure: true,
      };
    }
    default:
      return state;
  }
};
