/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ReduxStore } from 'src/customer/store/configureStore';
import service from './service';

/* https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers */

// eslint-disable-next-line import/prefer-default-export
export const list = createAsyncThunk(
  'EMONEY_CHAIN_LIST',
  async (_, { getState, requestId }) => {
    const { currentRequestId, status } = (getState() as ReduxStore).emoney
      .chain;
    if (status !== 'request' || requestId !== currentRequestId) {
      // do nothing
      return Promise.resolve([]);
    }
    return service.list();
  },
);
