import Safe, { EthersAdapter } from '@safe-global/protocol-kit';
import { ethers } from 'ethers';
import { createPublicClient, http, trim } from 'viem';
import { Chain } from 'src/types/emoney/Chain';
import { SafeSdkProps } from 'src/types/emoney/Wallet';

export const connectInjectedProvider = (
  chain?: Chain,
): ethers.BrowserProvider => {
  if (typeof window === 'undefined' || !window.ethereum) {
    throw new Error('Ethereum object is not available on window');
  }

  return new ethers.BrowserProvider(window.ethereum, chain?.id);
};

export const switchEthereumChain = async (chainId: number): Promise<void> => {
  if (typeof window === 'undefined' || !window.ethereum) {
    throw new Error('Ethereum object is not available on window');
  }
  const hexChainId = ethers.toQuantity(chainId);
  await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: hexChainId }],
  });
};

export const connectSafe = async ({
  safeAddress,
  chain,
  notifyError,
}: SafeSdkProps & { notifyError?: (message: string) => void }): Promise<
  Safe | undefined
> => {
  if (!safeAddress || !chain) {
    console.error('Safe address or chain is not defined');
    return;
  }

  try {
    const provider = connectInjectedProvider();
    await switchEthereumChain(chain?.id);

    const safeOwner = await provider.getSigner(0);
    const ethAdapter = new EthersAdapter({
      ethers,
      signerOrProvider: safeOwner,
    });

    const sdk = await Safe.create({
      ethAdapter,
      safeAddress,
    });

    const safe = await sdk.connect({ ethAdapter, safeAddress });

    // eslint-disable-next-line consistent-return
    return safe;
  } catch (error) {
    if (notifyError) notifyError((error as Error)?.message);
    console.error('Failed to connect safe:', error);
  }
};

export const isSmartContract = async (chain: Chain, address: string) => {
  if (!chain || !address) return;
  const publicClient = createPublicClient({
    chain,
    transport: http(),
  });
  try {
    const bytecode = await (publicClient as any)?.getBytecode({ address });
    // eslint-disable-next-line consistent-return
    return !!(bytecode && trim(bytecode) !== '0x');
  } catch (e) {
    // local chains will always fail, return false
    console.error(e);
    // eslint-disable-next-line consistent-return
    return false;
  }
};
