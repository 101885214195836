import { combineReducers } from 'redux';
import account from './Account/reducer';
import send from './Account/Send/reducer';
import { TreasuryReduxStore } from './types';

/**
 * When `account` has been refactored to use redux-toolkit, we won't need to define the type for combineReducers anymore
 */

export default combineReducers<
  TreasuryReduxStore & { send: ReturnType<typeof send> }
>({
  account,
  send,
});
