export default {
  LIST_REQUEST: 'EMONEY_ADDRESS_LIST_REQUEST',
  LIST_SUCCESS: 'EMONEY_ADDRESS_LIST_SUCCESS',
  LIST_FAILURE: 'EMONEY_ADDRESS_LIST_FAILURE',

  SELECT_REQUEST: 'EMONEY_ADDRESS_SELECT_REQUEST',
  SELECT_SUCCESS: 'EMONEY_ADDRESS_SELECT_SUCCESS',
  SELECT_FAILURE: 'EMONEY_ADDRESS_SELECT_FAILURE',

  GOTO_REQUEST: 'EMONEY_ADDRESS_GOTO_REQUEST',
  GOTO_SUCCESS: 'EMONEY_ADDRESS_GOTO_SUCCESS',
  GOTO_FAILURE: 'EMONEY_ADDRESS_GOTO_FAILURE',

  CREATE_REQUEST: 'EMONEY_ADDRESS_CREATE_REQUEST',
  CREATE_SUCCESS: 'EMONEY_ADDRESS_CREATE_SUCCESS',
  CREATE_FAILURE: 'EMONEY_ADDRESS_CREATE_FAILURE',
  CREATE_RESET: 'EMONEY_ADDRESS_CREATE_RESET',

  LINK_REQUEST: 'EMONEY_ADDRESS_LINK_REQUEST',
  LINK_SUCCESS: 'EMONEY_ADDRESS_LINK_SUCCESS',
  LINK_FAILURE: 'EMONEY_ADDRESS_LINK_FAILURE',
  LINK_MULTI_SIG_PENDING: 'EMONEY_ADDRESS_LINK_MULTI_SIG_PENDING',
  LINK_RESET: 'EMONEY_ADDRESS_LINK_RESET',
  LINK_MESSAGE: 'I hereby declare that I am the address owner.',

  SIGNATURE_PROMPT: 'You will be prompted to sign a message in your wallet.',

  VISIBILITY_REQUEST: 'EMONEY_ADDRESS_VISIBILITY_REQUEST',
  VISIBILITY_SUCCESS: 'EMONEY_ADDRESS_VISIBILITY_SUCCESS',

  LINK_ERROR_ANOTHER: 'Account already linked to another profile',
  LINK_ERROR_YOURS: 'Account already linked to your profile',
};
