import { getSignMessageLibDeployment } from '@gnosis.pm/safe-deployments';
import SafeApiKit from '@safe-global/api-kit';
import Safe, {
  EthersAdapter,
  hashSafeMessage,
} from '@safe-global/protocol-kit';
import SafeAppsSDK from '@safe-global/safe-apps-sdk';
import {
  OperationType,
  SafeTransactionDataPartial,
} from '@safe-global/safe-core-sdk-types';
import { ethers, ZeroAddress } from 'ethers';
import { Chain } from '../Chain/types';

export const SUPPORTED_SAFE_VERSION = '1.3.0';
export const checkVersion = (version: string): boolean => {
  const result = version.localeCompare(SUPPORTED_SAFE_VERSION, undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  return result >= 0;
};

/**
 * Checks if address on chain is a safe.
 * Will throw an error if the Safe version is outdated.
 */
export const isSafe = async (address: string, chainId: number) => {
  let safeInfo;
  try {
    const safeService = new SafeApiKit({
      chainId: BigInt(chainId),
    });

    safeInfo = await safeService.getSafeInfo(address);
  } catch (e) {
    // No action needed, probably the chainId is not supported in Safe.
    return false;
  }
  if (safeInfo?.version) {
    const isVersionSupported = checkVersion(safeInfo.version);
    if (!isVersionSupported) {
      throw new Error(
        `Your Safe version (${safeInfo?.version}) is outdated. Please update your Safe to the latest version. Supported: ${SUPPORTED_SAFE_VERSION}`,
      );
    }
  }
  if (safeInfo) {
    return true;
  }
  return false;
};

/**
 * Not used anymore, but keeping this here for reference, we might want to support on-chain signatures as well in the future.
 * There is one thing better about the on-chain signatures: the ability to sign messages on your own time.
 *
 * Off-chain signatures requires all owners to be ready to sign at the same time, so it can return a signature and call the EMS.
 * By default, Safe Apps SDK uses off-chain signatures for transactions.
 */

export const enforceOnChainSignatureForSafe = async (
  connectors: () => any[], // wagmi connectors
) => {
  const safe = connectors().find((c) => c.id === 'safe');

  if (safe && safe?.ready) {
    /** SafeAppsSDK is meant to be run within an iframe. */
    const appsSdk = new SafeAppsSDK();
    // Enforce on-chain signatures for Safe Apps
    try {
      appsSdk?.eth?.setSafeSettings([{ offChainSigning: false }]);
    } catch (err) {
      console.error(err);
    }
  }
};

/** Not used anymore, keeping for reference, see: enforceOnChainSignatureForSafe */
export const proposeSafeOnChainSignature = async (
  message: string,
  safeAddress: string | `0x${string}`,
  chain: Chain,
  setSafeTxHash: (hash: string) => void,
): Promise<void> => {
  const provider = new ethers.BrowserProvider(window.ethereum, chain?.id);
  const safeOwner = await provider.getSigner(0);
  const ethAdapter = new EthersAdapter({ ethers, signerOrProvider: safeOwner });
  const protocolKit = await Safe.create({ ethAdapter, safeAddress });
  const safeSdk = await protocolKit.connect({ ethAdapter, safeAddress });

  const safeVersion = await safeSdk.getContractVersion();

  /** We need the address of the SignMessageLib contract */
  const signMessageLibLibrary = getSignMessageLibDeployment({
    version: safeVersion, // version filtering
    // there is a network filter, but it's not working properly
  });

  const signMessageLibAddress =
    signMessageLibLibrary?.networkAddresses[chain?.id as number];

  const signMessageLibContract = await ethAdapter.getSignMessageLibContract({
    safeVersion,
    customContractAddress: signMessageLibAddress,
  });

  const messageHash = hashSafeMessage(message);
  const txData = signMessageLibContract.encode('signMessage', [messageHash]);

  const safeTransactionData: SafeTransactionDataPartial = {
    to: signMessageLibAddress as `0x${string}`,
    value: '0',
    data: txData,
    operation: OperationType.DelegateCall,
    refundReceiver: ZeroAddress,
  };

  const safeTransaction = await safeSdk?.createTransaction({
    transactions: [safeTransactionData],
  });

  const safeTransactionHash = await safeSdk.getTransactionHash(safeTransaction);

  setSafeTxHash(safeTransactionHash as string);

  const signerSignature = await safeSdk.signHash(safeTransactionHash);

  const safeService = new SafeApiKit({
    chainId: BigInt(chain?.id as number),
  });

  await safeService
    .proposeTransaction({
      safeAddress,
      safeTransactionData: safeTransaction.data,
      safeTxHash: safeTransactionHash,
      senderAddress: ethers.getAddress(safeOwner.address),
      senderSignature: signerSignature.data,
    })
    .catch((error) => {
      console.error('Error proposing transaction', error);
      throw new Error(error);
    });
};
