export const SET_REQUEST_ID = 'EMONEY_ACCOUNT_SET_REQUEST_ID';
export const LIST_REQUEST = 'EMONEY_ACCOUNT_LIST_REQUEST';
export const LIST_SUCCESS = 'EMONEY_ACCOUNT_LIST_SUCCESS';
export const LIST_FAILURE = 'EMONEY_ACCOUNT_LIST_FAILURE';
export const SELECT_REQUEST = 'EMONEY_ACCOUNT_SELECT_REQUEST';
export const SELECT_SUCCESS = 'EMONEY_ACCOUNT_SELECT_SUCCESS';
export const SELECT_FAILURE = 'EMONEY_ACCOUNT_SELECT_FAILURE';
export const READ_REQUEST = 'EMONEY_ACCOUNT_READ_REQUEST';
export const READ_SUCCESS = 'EMONEY_ACCOUNT_READ_SUCCESS';
export const READ_FAILURE = 'EMONEY_ACCOUNT_READ_FAILURE';
export const AUTO_SELECT_REQUEST = 'EMONEY_ACCOUNT_AUTO_SELECT_REQUEST';
export const AUTO_SELECT_SUCCESS = 'EMONEY_ACCOUNT_AUTO_SELECT_SUCCESS';
export const AUTO_SELECT_FAILURE = 'EMONEY_ACCOUNT_AUTO_SELECT_FAILURE';
export const AUTO_SELECT_RESET = 'EMONEY_ACCOUNT_AUTO_SELECT_RESET';
export const SELECT_ACCOUNT = 'EMONEY_ACCOUNT_SELECT_ACCOUNT';
export const CREATE_REQUEST = 'EMONEY_ACCOUNT_CREATE_REQUEST';
export const CREATE_SUCCESS = 'EMONEY_ACCOUNT_CREATE_SUCCESS';
export const CREATE_FAILURE = 'EMONEY_ACCOUNT_CREATE_FAILURE';
export const UPDATE_ACCOUNT_FAILURE = 'EMONEY_ACCOUNT_UPDATE_REQUEST';
export const UPDATE_ACCOUNT_REQUEST = 'EMONEY_ACCOUNT_UPDATE_SUCCESS';
export const UPDATE_ACCOUNT_SUCCESS = 'EMONEY_ACCOUNT_UPDATE_FAILURE';
export const URL_ACTION_NONE = '';
export const URL_ACTION_ADD = 'add';
export const URL_ACTION_SEND = 'send';
export const URL_ACTION_EXCHANGE = 'exchange';
export const URL_ACTION_MORE = 'more';
export const URL_ACTIONS = [
  URL_ACTION_NONE,
  URL_ACTION_ADD,
  URL_ACTION_SEND,
  URL_ACTION_EXCHANGE,
  URL_ACTION_MORE,
];

export default {
  SET_REQUEST_ID,
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  AUTO_SELECT_REQUEST,
  AUTO_SELECT_SUCCESS,
  AUTO_SELECT_FAILURE,
  AUTO_SELECT_RESET,
  SELECT_ACCOUNT,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  URL_ACTION_NONE,
  URL_ACTION_ADD,
  URL_ACTION_SEND,
  URL_ACTION_EXCHANGE,
  URL_ACTION_MORE,
  URL_ACTIONS,
};
