export const LIST_REQUEST = 'EMONEY_ORDER_LIST_REQUEST';
export const LIST_SUCCESS = 'EMONEY_ORDER_LIST_SUCCESS';
export const LIST_FAILURE = 'EMONEY_ORDER_LIST_FAILURE';
export const SUBSCRIBE_REQUEST = 'EMONEY_ORDER_SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'EMONEY_ORDER_SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'EMONEY_ORDER_SUBSCRIBE_FAILURE';
export const SUBSCRIBE_MESSAGE = 'EMONEY_ORDER_SUBSCRIBE_MESSAGE';
export const UNSUBSCRIBE_REQUEST = 'EMONEY_ORDER_UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'EMONEY_ORDER_UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'EMONEY_ORDER_UNSUBSCRIBE_FAILURE';
export const READ_REQUEST = 'EMONEY_ORDER_READ_REQUEST';
export const READ_SUCCESS = 'EMONEY_ORDER_READ_SUCCESS';
export const READ_FAILURE = 'EMONEY_ORDER_READ_FAILURE';
export const CREATE_REQUEST = 'EMONEY_ORDER_CREATE_REQUEST';
export const CREATE_SUCCESS = 'EMONEY_ORDER_CREATE_SUCCESS';
export const CREATE_MULTI_SIG_PENDING = 'EMONEY_ORDER_CREATE_MULTI_SIG_PENDING';
export const CREATE_FAILURE = 'EMONEY_ORDER_CREATE_FAILURE';
export const RESET = 'EMONEY_ORDER_RESET';
export default {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_MESSAGE,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  RESET,
};
