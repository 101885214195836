import React, { useEffect } from 'react';
import useTokens from '../emoney/Token/hooks';
import useLogin from '../iam/Login/hooks';

const DataFetcher = ({ children }: { children: React.ReactNode }) => {
  const { tokens, getTokens } = useTokens();
  const { isVerified } = useLogin();

  useEffect(() => {
    if (isVerified && tokens?.length === 0) {
      getTokens();
    }
  }, [tokens, isVerified]);

  return <>{children}</>;
};
export default DataFetcher;
