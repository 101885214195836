import { stringifyQuery } from 'src/utils/url';
import {
  STEP_CONNECT_WALLET,
  STEP_CREATE_ACCOUNT,
  STEP_END,
  STEP_LOGIN,
  STEP_PROFILE_KYC,
  STEP_REQUEST_IBAN,
  STEP_SIGNUP,
  STEP_START,
  STEP_USER_KYC,
  STEP_VERIFY_EMAIL,
} from '../constants';
import { GetSignupUriProps, NormalizedQueryParams, QueryParams } from './types';

export const getStepInt = (step: string) => {
  switch (step) {
    case STEP_SIGNUP:
    case STEP_LOGIN:
    case STEP_VERIFY_EMAIL:
      return 1;
    case STEP_PROFILE_KYC:
    case STEP_CONNECT_WALLET:
    case STEP_CREATE_ACCOUNT:
      return 2;
    case STEP_USER_KYC:
    case STEP_REQUEST_IBAN:
      return 3;
    case STEP_END:
      return 4;
    case STEP_START:
    default:
      return 0;
  }
};

export const getSignupUri = ({
  redirectUri,
  codeChallenge,
  codeChallengeMethod,
  state,
  address,
  partner,
}: GetSignupUriProps) => {
  let q = {};
  if (codeChallenge) q = { code_challenge: codeChallenge };
  if (codeChallengeMethod)
    q = { ...q, code_challenge_method: codeChallengeMethod };
  if (state) q = { ...q, state };
  if (address) q = { ...q, address };
  if (partner) q = { ...q, partner };
  const qs = stringifyQuery(q);
  return qs ? `${redirectUri}&${qs}` : redirectUri;
};

export const getValidRedirectUri = (
  redirectUri?: string,
  allowedRedirectUris?: string,
): string => {
  const uris = allowedRedirectUris?.split(',');
  if (!uris || !uris?.length) {
    throw new Error('No redirect_uri is registered for client');
  }
  const rUri = redirectUri?.trim();
  if (!rUri && uris.length === 1) return uris[0];
  if (!rUri) {
    throw new Error('redirect_uri parameter is missing');
  }

  const isUriAllowed = uris.find((u: string) => u?.trim() === rUri);

  if (!isUriAllowed) {
    throw new Error(
      'the redirect URI does not match one of the registered redirect URIs for the application',
    );
  }
  return rUri;
};

export const normalizeQueryKeys = (
  query: QueryParams,
): NormalizedQueryParams => {
  const {
    client_id: clientId,
    redirect_uri: redirectUri,
    code_challenge: codeChallenge,
    code_challenge_method: codeChallengeMethod = 'S256',
    skipCreateAccount,
    ...rest
  } = query;
  return {
    clientId,
    redirectUri,
    codeChallenge,
    codeChallengeMethod,
    skipCreateAccount:
      skipCreateAccount?.toLowerCase &&
      skipCreateAccount.toLowerCase() === 'true',
    ...rest,
  };
};
