import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import actions from './actions';
import { UseNotifyReturn } from './types';

const useNotification = (): UseNotifyReturn => {
  const dispatch = useDispatch();

  return {
    notifyError: useCallback(
      (message: string) => dispatch(actions.error(message)),
      [dispatch],
    ),
    notifyInfo: useCallback(
      (message: string) => dispatch(actions.info(message)),
      [dispatch],
    ),
    notifyWarning: useCallback(
      (message: string) => dispatch(actions.warning(message)),
      [dispatch],
    ),
  };
};

export default useNotification;
