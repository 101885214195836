import { AnyAction } from 'redux';
import { updateItemInArray } from 'src/utils/array';
import {
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from './constants';
import { TreasuryAccountReduxStore } from './types';

const initial: TreasuryAccountReduxStore = {
  list: [],
  isListRequest: false,
  isListSuccess: false,
  isListFailure: undefined,
  selected: undefined,
  details: [],
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  isCreateRequest: false,
  isCreateSuccess: false,
  isCreateFailure: undefined,
  isUpdateRequest: false,
  isUpdateSuccess: false,
  isUpdateFailure: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case LIST_REQUEST: {
      return {
        ...state,
        isListRequest: true,
        isListSuccess: false,
        isListFailure: undefined,
      };
    }
    case LIST_SUCCESS: {
      const { list } = action;
      return {
        ...state,
        list,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
      };
    }
    case LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isListRequest: false,
        isListSuccess: false,
        isListFailure: error,
      };
    }
    case READ_REQUEST: {
      return {
        ...state,
        isReadRequest: true,
        isReadSuccess: false,
        isReadFailure: undefined,
      };
    }
    case READ_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        selected: item,
        list: updateItemInArray(item, state.list),
        details: updateItemInArray(item, state.details),
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
      };
    }
    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }
    case CREATE_REQUEST: {
      return {
        ...state,
        isCreateRequest: true,
        isCreateSuccess: false,
        isCreateFailure: undefined,
      };
    }
    case CREATE_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        list: [...state.list, item],
        isCreateRequest: false,
        isCreateSuccess: true,
        isCreateFailure: undefined,
      };
    }
    case CREATE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreateRequest: false,
        isCreateSuccess: false,
        isCreateFailure: error,
      };
    }
    case UPDATE_REQUEST: {
      return {
        ...state,
        isUpdateRequest: true,
        isUpdateSuccess: false,
        isUpdateFailure: undefined,
      };
    }
    case UPDATE_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        list: updateItemInArray(item, state.list),
        details: updateItemInArray(item, state.details),
        isUpdateRequest: false,
        isUpdateSuccess: true,
        isUpdateFailure: undefined,
      };
    }
    case UPDATE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isUpdateRequest: false,
        isUpdateSuccess: false,
        isUpdateFailure: error,
      };
    }

    default:
      return { ...state };
  }
};
