export const LIST_REQUEST = 'TREASURY_ACCOUNT_LIST_REQUEST';
export const LIST_SUCCESS = 'TREASURY_ACCOUNT_LIST_SUCCESS';
export const LIST_FAILURE = 'TREASURY_ACCOUNT_LIST_FAILURE';
export const READ_REQUEST = 'TREASURY_ACCOUNT_READ_REQUEST';
export const READ_SUCCESS = 'TREASURY_ACCOUNT_READ_SUCCESS';
export const READ_FAILURE = 'TREASURY_ACCOUNT_READ_FAILURE';
export const CREATE_REQUEST = 'TREASURY_ACCOUNT_CREATE_REQUEST';
export const CREATE_SUCCESS = 'TREASURY_ACCOUNT_CREATE_SUCCESS';
export const CREATE_FAILURE = 'TREASURY_ACCOUNT_CREATE_FAILURE';
export const UPDATE_REQUEST = 'TREASURY_ACCOUNT_UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'TREASURY_ACCOUNT_UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'TREASURY_ACCOUNT_UPDATE_FAILURE';
export const ACTION_NONE = '';
export const ACTION_CREATE = 'create';
export const ACTIONS = [ACTION_NONE, ACTION_CREATE];
export const KIND_MASTER = 'master';
export const KIND_MONERIUM = 'own';
export const KIND_THIRD_PARTY = 'thirdparty';
export const KINDS = [KIND_MONERIUM]; // supported
export const STATE_REQUESTED = 'requested';
export const STATE_PENDING = 'pending';
export const STATE_APPROVED = 'approved';
export const STATES = [STATE_REQUESTED, STATE_PENDING, STATE_APPROVED];

export default {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  ACTION_NONE,
  ACTION_CREATE,
  ACTIONS,
  KIND_MONERIUM,
  KIND_THIRD_PARTY,
  KINDS,
  STATE_REQUESTED,
  STATE_PENDING,
  STATE_APPROVED,
  STATES,
};
