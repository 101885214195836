/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ReduxStore } from 'customer/types';
import service from './service';

/* https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers */

const list = createAsyncThunk(
  'EMONEY_TOKEN_LIST',
  async (_, { getState, requestId }) => {
    const tokenList = (getState() as ReduxStore).emoney.token.list;
    if (!tokenList.length) {
      const { currentRequestId, status } = (getState() as ReduxStore).emoney
        .token;
      if (status !== 'request' || requestId !== currentRequestId) {
        // do nothing
        return Promise.resolve([]);
      }
      return service.list();
    }
    return tokenList;
  },
);

export {
  // eslint-disable-next-line import/prefer-default-export
  list,
};
