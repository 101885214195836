import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { ReduxStore, useAppDispatch } from 'src/customer/store/configureStore';
import { ValidationErrors } from 'src/types/validationTypes';
import actions from './actions';
import { AuthContext, LoginRequest } from './types';

interface UseLoginReturn {
  authContext?: AuthContext;
  isInvited: boolean;
  isLoginRequest: boolean;
  isLoginSuccess: boolean;
  isLoginFailure: ValidationErrors | undefined;
  isVerified: boolean;
  email: string | undefined;
  login: (data: LoginRequest) => Promise<AuthContext | void>;
  read: (userId: string) => Promise<AuthContext | void>;
}

const selectAuthUser = (state: ReduxStore) => state.iam?.login?.auth;
const selectAuthContext = (state: ReduxStore) => state.iam?.login;
const selectIsLoginRequest = () =>
  useSelector(
    (state: ReduxStore) => !!state.iam?.login?.isLoginRequest,
    shallowEqual,
  );
const selectIsLoginSuccess = () =>
  useSelector(
    (state: ReduxStore) => !!state.iam?.login?.isLoginSuccess,
    shallowEqual,
  );
const selectIsLoginFailure = () =>
  useSelector(
    (state: ReduxStore) => state.iam?.login?.isLoginFailure,
    shallowEqual,
  );
const selectLoginEmail = () =>
  useSelector(
    (state: ReduxStore) => state.iam?.login?.auth?.email,
    shallowEqual,
  );

const authSelector = createSelector(
  selectAuthUser,
  (authContext) => authContext?.auth,
);

const authContextSelector = createSelector(
  selectAuthContext,
  (ctx) => ctx.auth,
);

const useLogin = (): UseLoginReturn => {
  const dispatch = useAppDispatch();
  const auth = useSelector(authSelector, shallowEqual);
  const isLoginRequest = selectIsLoginRequest();
  const isLoginSuccess = selectIsLoginSuccess();
  const isLoginFailure = selectIsLoginFailure();
  const email = selectLoginEmail();

  return {
    authContext: useSelector(authContextSelector, shallowEqual),
    isVerified: !!auth?.verified,
    isInvited: !!auth?.invited,
    isLoginRequest,
    isLoginSuccess,
    isLoginFailure,
    email,
    login: useCallback(
      (data: LoginRequest) => dispatch(actions.login(data)),
      [dispatch],
    ),
    read: useCallback(
      (userId: string) => dispatch(actions.read(userId)).catch(console.error),
      [dispatch],
    ),
  };
};

export default useLogin;
