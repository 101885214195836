import { ValidationErrors } from './validationTypes';

export interface ServiceState {
  status: Status;
  currentRequestId: string;
  error?: ValidationErrors | string;
}

/** @deprecated Use import { Status } from 'src/types/requests' */
export enum RequestStatus {
  Idle = 'idle',
  Pending = 'pending',
  Rejected = 'rejected',
  Fulfilled = 'fulfilled',
}
/** An editor friendly version of RequestStatus  */
export enum Status {
  IDLE = 'idle',
  PENDING = 'pending',
  REJECTED = 'rejected',
  FULFILLED = 'fulfilled',
}
export default Status;
