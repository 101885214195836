export default {
  CONNECT_REQUEST: 'ETH_BLK_CONNECT_REQUEST',
  CONNECT_SUCCESS: 'ETH_BLK_CONNECT_SUCCESS',
  CONNECT_FAILURE: 'ETH_BLK_CONNECT_FAILURE',
  DISCONNECT_REQUEST: 'ETH_BLK_DISCONNECT_REQUEST',
  DISCONNECT_SUCCESS: 'ETH_BLK_DISCONNECT_SUCCESS',
  DISCONNECT_FAILURE: 'ETH_BLK_DISCONNECT_FAILURE',
  MESSAGE_REQUEST: 'ETH_BLK_MESSAGE_REQUEST',
  MESSAGE_SUCCESS: 'ETH_BLK_MESSAGE_SUCCESS',
  MESSAGE_FAILURE: 'ETH_BLK_MESSAGE_FAILURE',
};
