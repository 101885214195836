import { createListenerMiddleware } from '@reduxjs/toolkit';
import { ReduxStore } from 'src/customer/store/configureStore';
import history from 'src/history';

const listenerMiddleware = createListenerMiddleware<ReduxStore>();

listenerMiddleware.startListening({
  // return true when the listener should run
  predicate: (action, currentState) =>
    (action?.error?.common === 'Not authenticated' ||
      action?.error?.message === 'Not authenticated') &&
    currentState?.iam?.login?.isLoginSuccess,
  // Run whatever additional side-effect-y logic you want here
  effect: async (action, listenerApi) => {
    listenerApi.dispatch({ type: 'IAM_LOGOUT_SUCCESS' });
    history?.push('/login');
  },
});

export default listenerMiddleware.middleware;
