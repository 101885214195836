import { currencySortOrder } from 'utils/emoney/tokens';
import { CurrencyCode } from 'src/types/emoney/Token';
import { equal } from 'src/utils/address';
import { TreasuryAccount } from 'customer/components/treasury/Account/types';
import { ChainName } from '../Chain/types';
import { Order } from '../Order/types';
import {
  Account,
  AccountByCurrency,
  AccountWithManyTreasuryAccounts,
} from './types';

const getAddresses = (accounts: Account[]): string[] =>
  Object.keys(
    accounts && accounts.length > 0
      ? accounts.reduce(
          (accum, account) => ({
            ...accum,
            [account.address]: true,
          }),
          {},
        )
      : {},
  );

/**
 * Finds the default account based on the following criteria
 * - currency sort order
 * - highest balance
 * - treasury account
 * @param accounts list of accounts
 * @returns the default account based on criteria in description
 */
export const selectDefaultAccount = (accounts: Account[]): Account => {
  if (!Array.isArray(accounts) || accounts.length <= 0) {
    throw Error('No accounts found when trying to select the default account');
  }
  if (accounts.length === 1) {
    return accounts[0];
  }

  const sortedAccounts = [...accounts.filter((a) => a.meta?.isVisible)].sort(
    (x, y) => {
      // Sort by currency first
      const xCurrencyIndex = currencySortOrder.indexOf(
        x?.currency as CurrencyCode,
      );
      const yCurrencyIndex = currencySortOrder.indexOf(
        y?.currency as CurrencyCode,
      );

      if (xCurrencyIndex !== yCurrencyIndex) {
        return xCurrencyIndex - yCurrencyIndex;
      }

      // If currencies are the same, sort by balance
      return parseFloat(y?.balance || '0') - parseFloat(x?.balance || '0');
    },
  );

  return (
    sortedAccounts.find((account) => account?.iban !== undefined) ||
    sortedAccounts[0]
  );
};

const filterTreasuryAccounts = (
  treasuryAccounts: TreasuryAccount[],
  account: Account,
): AccountWithManyTreasuryAccounts => ({
  ...account,
  treasuryAccounts: treasuryAccounts?.filter(
    (ta: TreasuryAccount) => ta.emoneyAccountId === account.id,
  ),
});

/**
 * Sort function for accounts that prioritizes the following:
 * 1. Connected account/wallet
 * 2. Has an IBAN
 * 3. Account balance
 * @param a emoney account
 * @param b emoney account
 * @param address address of connected wallet
 * @param chain chain name of connected wallet
 * @param treasuryAccounts list of all treasury accounts
 */
export const sortAccounts = (
  a: AccountWithManyTreasuryAccounts,
  b: AccountWithManyTreasuryAccounts,
  address?: string,
  chain?: ChainName,
  treasuryAccounts: TreasuryAccount[] = [],
): number => {
  let addressChainMatchA = 0;
  let addressChainMatchB = 0;

  if (equal(a.address, address) && equal(a.chain, chain)) {
    addressChainMatchA = 1;
  } else if (a.address === undefined || a.chain === undefined) {
    addressChainMatchA = -1;
  }
  if (equal(b.address, address) && equal(b.chain, chain)) {
    addressChainMatchB = 1;
  } else if (b.address === undefined || b.chain === undefined) {
    addressChainMatchB = -1;
  }

  // 1. Sorting by address and chain match (connected wallet)
  if (addressChainMatchA !== addressChainMatchB) {
    return addressChainMatchB - addressChainMatchA;
  }

  // 2. Treasury account match comparison
  const treasuryMatchA = treasuryAccounts.some(
    (ta) => ta.emoneyAccountId === a.id,
  )
    ? 1
    : 0;
  const treasuryMatchB = treasuryAccounts.some(
    (ta) => ta.emoneyAccountId === b.id,
  )
    ? 1
    : 0;
  if (treasuryMatchA !== treasuryMatchB) {
    return treasuryMatchB - treasuryMatchA;
  }

  // 3. Balance - descending
  const balanceA = Number(a.balance || 0);
  const balanceB = Number(b.balance || 0);
  return balanceB - balanceA;
};

export const sumBalancesByCurrency = (
  accounts: Account[],
): Record<CurrencyCode, number> => {
  return accounts.reduce<Record<CurrencyCode, number>>((acc, account) => {
    if (account?.meta?.isVisible) {
      if (account?.currency) {
        const currency: CurrencyCode = account?.currency;
        if (!acc[currency]) {
          acc[currency] = 0;
        }
        if (account?.balance) {
          acc[currency] += Number(account?.balance);
        }
      }
    }
    return acc;
  }, {} as Record<CurrencyCode, number>);
};

export const mapAccountsToCurrency = (
  treasuryAccounts: TreasuryAccount[],
  accounts: Account[],
): AccountByCurrency[] => {
  const byCurrency = accounts.reduce<Record<CurrencyCode, AccountByCurrency>>(
    (prev, account) => {
      const merge: AccountWithManyTreasuryAccounts = filterTreasuryAccounts(
        treasuryAccounts,
        account,
      );

      const currency = account.currency as CurrencyCode;
      if (!prev[currency]) {
        // eslint-disable-next-line no-param-reassign
        prev[currency] = {
          currency,
          accounts: [merge],
        };
      } else {
        prev[currency].accounts.push(merge);
      }

      return prev;
    },
    {} as Record<CurrencyCode, AccountByCurrency>,
  );

  const sorted: AccountByCurrency[] = Object.values(byCurrency).sort((a, b) => {
    const indexA = currencySortOrder.indexOf(a.currency);
    const indexB = currencySortOrder.indexOf(b.currency);
    return indexA - indexB;
  });

  return sorted;
};

const sortAccountsByAmount = (
  accounts: Account[],
  treasuryAccounts: TreasuryAccount[],
) => {
  const returnArray = accounts.map((a) => {
    return filterTreasuryAccounts(treasuryAccounts, a);
  });

  returnArray.sort((a, b) => {
    const aBalance = parseFloat(a.balance || '');
    const bBalance = parseFloat(b.balance || '');

    // Check for NaN (which means the balance couldn't be parsed to a number)
    if (Number.isNaN(aBalance) && Number.isNaN(bBalance)) {
      return 0;
    }
    if (Number.isNaN(aBalance)) {
      return 1;
    }
    if (Number.isNaN(bBalance)) {
      return -1;
    }

    return bBalance - aBalance;
  });

  return returnArray;
};

/**
 * Calculates a new temporary balance based on the amount from a placed order.
 * Reason: When demoing we want the give the impression of payment is received instantly.
 * The balance is not affected when an order is placed because it hasn't been processed.
 * Therefore, we simulate the balance change by optimistically hoping that the order will be
 * processed correctly. This is only done when orders are in placed state.
 * @param order order that is being placed
 * @param account selected emoney account
 */
export const calculateBalanceFromPendingOrder = (
  order: Order,
  account: Account,
): string => {
  if (typeof order?.amount !== 'string') {
    throw Error('Order missing to calculate new balance');
  }
  if (typeof account?.balance !== 'string') {
    throw Error('Account balance missing to calculate new balance');
  }
  if (order.meta.state !== 'placed') {
    // We only alter the balance if the order is in placed state
    return account.balance;
  }

  const amount = parseFloat(order.amount);
  const fee = parseFloat(order.totalFee || '0');
  const balance = parseFloat(account.balance);

  if (order.kind === 'issue') {
    return (balance + amount - fee).toString();
  }
  if (order.kind === 'redeem') {
    return (balance - amount).toString();
  }

  // we should not reach this point.
  throw Error('Unknown error occurred while calculating account balance');
};

export const sortAccountsByCurrency = (accounts: Account[]) => {
  return accounts?.sort((a, b) => {
    const aIndex = currencySortOrder.indexOf(a.currency as CurrencyCode);
    const bIndex = currencySortOrder.indexOf(b.currency as CurrencyCode);

    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });
};

export default {
  filterTreasuryAccounts,
  getAddresses,
  selectDefaultAccount,
  sortAccountsByAmount,
};
