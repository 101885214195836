import {
  Chain as RainbowKitChain,
  getDefaultConfig,
  Wallet,
  WalletDetailsParams,
} from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  braveWallet,
  coinbaseWallet,
  imTokenWallet,
  injectedWallet,
  ledgerWallet,
  // metaMaskWallet,
  rabbyWallet,
  safeWallet,
  walletConnectWallet,
  zerionWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createConnector } from 'wagmi';
import { safe } from 'wagmi/connectors';
import { getDefaultChains } from 'src/utils/emoney/chains';

const TEST_WALLETCONNECT_PROJECT_ID = 'e39e5a24285fe0a946cd3b6d9eada7af';
const PROD_WALLETCONNECT_PROJECT_ID = '9dfea1e28fa874dc0d8c95fb02d58886';

const projectId = __DEV__
  ? TEST_WALLETCONNECT_PROJECT_ID
  : PROD_WALLETCONNECT_PROJECT_ID;

const appName = 'Monerium';
const appDescription =
  'Monerium is an Electronic Money Institution authorized to issue money on blockchains';

/** Custom Safe Wallet config to add shimDisconnect and allowedDomains */
const customSafeWallet = (): Wallet => ({
  ...safeWallet(),
  createConnector: (walletDetails: WalletDetailsParams) => {
    return createConnector((config) => ({
      ...safe({ shimDisconnect: true, allowedDomains: [/app.safe.global$/] })(
        config,
      ),
      ...walletDetails,
    }));
  },
});

const chains = getDefaultChains();
const isSafe = customSafeWallet().installed;

const onlySafeWallet = [
  {
    groupName: 'Safe',
    wallets: [customSafeWallet],
  },
];

const wallets = isSafe
  ? onlySafeWallet
  : [
      {
        groupName: 'Recommended',
        wallets: [
          rabbyWallet,
          braveWallet,
          // metaMaskWallet,
          injectedWallet,
          customSafeWallet,
          walletConnectWallet,
          ledgerWallet,
        ],
      },
      {
        groupName: 'Others',
        wallets: [coinbaseWallet, zerionWallet, imTokenWallet, argentWallet],
      },
    ];

// eslint-disable-next-line import/prefer-default-export
export const config = getDefaultConfig({
  appName,
  appDescription,
  appUrl: 'https://monerium.app',
  appIcon: 'https://monerium.app/icon.png',
  projectId,
  chains: chains as unknown as readonly [RainbowKitChain, ...RainbowKitChain[]],
  ssr: false,
  multiInjectedProviderDiscovery: true,
  // storage: createStorage({
  //   storage: cookieStorage,
  // }),
  wallets,
});
