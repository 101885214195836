import AppAction from 'src/customer/actions';
import { AppThunk } from 'src/customer/store/configureStore';
import browserHistory from 'src/history';
import { CurrencyCode } from 'src/types/emoney/Token';
import { equal } from 'src/utils/address';
import { currencySortOrder } from 'src/utils/emoney/tokens';
import {
  AUTO_SELECT_FAILURE,
  AUTO_SELECT_REQUEST,
  AUTO_SELECT_RESET,
  AUTO_SELECT_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  SELECT_ACCOUNT,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants';
import service from './service';
import { Account, AutoSelectRequest, ListRequest, ReadRequest } from './types';
import { selectDefaultAccount } from './util';
/**
 * Fetches all accounts from the API
 */
const list = (param: ListRequest) =>
  AppAction<ListRequest, Account[]>({
    param,
    requestConst: LIST_REQUEST,
    successConst: LIST_SUCCESS,
    failureConst: LIST_FAILURE,
    service: service.list,
  });

/**
 * Fetch account from API that belongs to profile and select it
 */
const read = (param: ReadRequest) =>
  AppAction<ReadRequest, Account>({
    param,
    requestConst: READ_REQUEST,
    successConst: READ_SUCCESS,
    failureConst: READ_FAILURE,
    service: service.read,
  });

const selectAccount =
  (account: Account): AppThunk<void> =>
  (dispatch) => {
    service.setLastSelected(account.id);
    dispatch({ type: SELECT_ACCOUNT, account });
  };

const selectAccountReset = (): AppThunk<void> => (dispatch) =>
  dispatch({ type: SELECT_ACCOUNT, account: undefined });

const autoSelectReset = (): AppThunk<void> => (dispatch) =>
  dispatch({ type: AUTO_SELECT_RESET });

const autoSelect = (
  props: AutoSelectRequest,
  // Dependency injection for unit testing
  history = browserHistory,
  getLastSelectedAccount = service.getLastSelected,
  delLastSelectedAccount = service.delLastSelected,
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const { profileId, address, chain } = props;

    if (history.cancelled) return;

    const isWalletConnected = address && chain;

    const request = () => ({ type: AUTO_SELECT_REQUEST });

    const failure = (error?: Error) => ({
      type: AUTO_SELECT_FAILURE,
      error,
    });

    const success = (account: Account) => {
      dispatch(selectAccount(account));
      history?.push(`/accounts/${account.id}`);
      return {
        type: AUTO_SELECT_SUCCESS,
        account,
      };
    };

    /**
     * Return if auto select request is already in progress
     */
    const { emoney: e } = getState();
    if (e?.account.isAutoSelectRequest) return;
    if (history.cancelled) return;
    dispatch(request());

    try {
      const accounts = await dispatch(list({ profileId, wantBalances: true }));

      /**
       * If a wallet is connected
       * 1. Check if account exists and select it
       * 2. If account does not exist, redirect to link account page
       */
      if (isWalletConnected) {
        const account = accounts
          ?.filter(
            (a: Account) =>
              equal(a.address, address) &&
              a.meta?.isVisible &&
              a.chain === chain?.moneriumId?.chain,
          )
          .sort((a, b) => {
            return (
              currencySortOrder.indexOf(a.currency as CurrencyCode) -
              currencySortOrder.indexOf(b.currency as CurrencyCode)
            );
          })[0];

        if (account) {
          if (history.cancelled) return;
          dispatch(success(account));
          return;
        }

        const accountExists = accounts?.find((a: Account) =>
          equal(a.address, address),
        );

        if (!accountExists) {
          if (history.cancelled) return;
          history?.push(`/addresses/link/${address}`);
          dispatch(autoSelectReset());
          return;
        }
        if (history.cancelled) return;
        dispatch(autoSelectReset());
        history?.push('/accounts');
        return;
      }
      /**
       * If no accounts exist, redirect to accounts page
       */
      if (!accounts?.length) {
        dispatch(autoSelectReset());
        history?.push('/accounts');
        return;
      }
      /**
       * If a wallet is not connected
       * 1. Get last selected account
       * 2. If last selected account exists, select it
       * 3. If last selected account does not exist, select highest balance account
       */
      if (!isWalletConnected) {
        const lastAccountId = getLastSelectedAccount();

        if (lastAccountId) {
          const lastAccount = accounts?.find(
            (i) => i.id === lastAccountId && i?.meta?.isVisible,
          );

          if (lastAccount) {
            dispatch(success(lastAccount));

            return;
          }

          if (lastAccountId) delLastSelectedAccount();
        }

        dispatch(success(selectDefaultAccount(accounts)));
      }
    } catch (error) {
      dispatch(failure(<Error>error));
      console.error(error);
    }
  };
};

const update = (accountId: string, data: Partial<Account>): AppThunk<void> => {
  const request = () => ({ type: UPDATE_ACCOUNT_REQUEST });
  const success = (account: Account) => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: account,
  });
  const failure = (error?: Error) => ({
    type: UPDATE_ACCOUNT_FAILURE,
    error,
  });

  return async (dispatch, getState) => {
    const { emoney: e } = getState();
    if (e?.account.isUpdateAccountRequest) return;
    dispatch(request());
    try {
      const i = await service.updateAccount(accountId, data);
      dispatch(success(i));
    } catch (error) {
      dispatch(failure(<Error>error));
    }
  };
};

export default {
  list,
  selectAccount,
  selectAccountReset,
  autoSelect,
  read,
  update,
};
