import { AnyAction } from 'redux';
import { rfc3339 } from '@monerium/sdk';
import { v4 as uuid } from 'uuid';
import { updateItemInArray } from 'src/utils/array';
import {
  CREATE_FAILURE,
  CREATE_MULTI_SIG_PENDING,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  RESET,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_SUCCESS,
} from './constants';
import { EmoneyOrderReduxStore } from './types';

export const initial: EmoneyOrderReduxStore = {
  isListRequest: false,
  isListSuccess: false,
  isListFailure: undefined,

  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,

  isCreateRequest: false,
  isCreateSuccess: false,
  isCreateFailure: undefined,
  isCreateMultiSigPending: false,

  safeTxHash: '',

  list: [],
  ws: undefined,
  profileId: '',
  retries: 0,

  error: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case LIST_REQUEST: {
      return { ...state, isListRequest: true };
    }
    case LIST_SUCCESS: {
      const { list } = action;
      return {
        ...state,
        list,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
      };
    }
    case LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isListRequest: false,
        isListSuccess: false,
        isListFailure: error,
      };
    }

    case CREATE_REQUEST: {
      return { ...state, isCreateRequest: true };
    }
    case CREATE_MULTI_SIG_PENDING: {
      const { entry } = action;

      return {
        ...state,
        list: state.list,
        safeTxHash: entry?.signature?.safeTxHash,
        isCreateRequest: false,
        isCreateMultiSigPending: true,
        isCreateSuccess: false,
        isCreateFailure: undefined,
      };
    }
    case CREATE_SUCCESS: {
      const { entry } = action;
      return {
        ...state,
        list: updateItemInArray(entry, state?.list || []),
        isCreateRequest: false,
        isCreateSuccess: true,
        isCreateFailure: undefined,
      };
    }
    case CREATE_FAILURE: {
      let { error } = action;

      // todo: remove this when ems fixed error response
      // https://github.com/monerium/ems/issues/1311
      if (
        error?.reason?.Suberr?.message ===
        'Supporting document required for amounts greater than 15000'
      ) {
        error = {
          supportingDocument: error?.reason?.Suberr?.message,
        };
      }

      return {
        ...state,
        error,
        isCreateRequest: false,
        isCreateSuccess: false,
        isCreateFailure: error,
      };
    }
    case RESET: {
      return {
        ...state,
        error: undefined,
        isCreateRequest: false,
        isCreateSuccess: false,
        isCreateMultiSigPending: false,
        isCreateFailure: undefined,
      };
    }

    case READ_REQUEST: {
      return { ...state, isReadRequest: true };
    }
    case READ_SUCCESS: {
      const { item } = action;
      const list = updateItemInArray(item, state?.list || []);

      return {
        ...state,
        list,
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
        error: undefined,
      };
    }

    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }
    case SUBSCRIBE_REQUEST: {
      const { ws, profileId } = action;
      return {
        ...state,
        retries: (state?.retries || 0) + 1,
        ws,
        profileId,
        isSubscribeRequest: true,
        isSubscribeSuccess: false,
        isSubscribeFailure: undefined,
        error: undefined,
      };
    }
    case SUBSCRIBE_SUCCESS: {
      return {
        ...state,
        retries: 0,
        isSubscribeRequest: false,
        isSubscribeSuccess: true,
        isSubscribeFailure: undefined,
        error: undefined,
      };
    }
    case SUBSCRIBE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isSubscribeRequest: false,
        isSubscribeSuccess: false,
        isSubscribeFailure: error,
      };
    }
    case UNSUBSCRIBE_REQUEST: {
      return {
        ...state,
        isUnsubscribeRequest: true,
        isUnsubscribeFailure: undefined,
        error: undefined,
      };
    }
    case UNSUBSCRIBE_SUCCESS: {
      return {
        ...state,
        ws: undefined,
        isUnsubscribeRequest: false,
        isUnsubscribeSuccess: true,
        isSubscribeSuccess: true,
        isUnsubscribeFailure: undefined,
        error: undefined,
      };
    }
    case UNSUBSCRIBE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isUnsubscribeRequest: false,
        isUnsubscribeSuccess: false,
        isUnsubscribeFailure: error,
      };
    }

    default:
      return state;
  }
};
