import { combineReducers } from '@reduxjs/toolkit';
import account from './Account/reducer';
import address from './Address/reducer';
import chain from './Chain/slice';
import order from './Order/reducer';
import token from './Token/slice';
import tx from './Tx/reducer';
import { EmoneyReduxStore } from './types';

export default combineReducers<EmoneyReduxStore>({
  account,
  address,
  chain,
  order,
  token,
  tx,
});
