import React from 'react';
import { DisclaimerComponent } from '@rainbow-me/rainbowkit';

const Disclaimer: DisclaimerComponent = ({ Link, Text }) => {
  return (
    <Text>
      By connecting, you are confirming that you have carefully read and
      comprehended Monerium&apos;s{' '}
      <Link href="https://monerium.com/policies/personal-terms-of-service/">
        Terms of Service
      </Link>
    </Text>
  );
};
export default Disclaimer;
