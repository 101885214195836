import { AnyAction } from 'redux';
import { updateItemInArray } from 'src/utils/array';
import { READ_SUCCESS as ORDER_READ_SUCCESS } from '../Order/constants';
import { Order } from '../Order/types';
import {
  AUTO_SELECT_FAILURE,
  AUTO_SELECT_REQUEST,
  AUTO_SELECT_RESET,
  AUTO_SELECT_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  SELECT_ACCOUNT,
  SET_REQUEST_ID,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants';
import { Account, EmoneyAccountReduxStore, ListRequest } from './types';
import { calculateBalanceFromPendingOrder } from './util';

export const initial: EmoneyAccountReduxStore = {
  isListRequest: false,
  isListSuccess: false,
  isListBalancesSuccess: false,
  isListFailure: undefined,
  isAutoSelectRequest: false,
  isAutoSelectSuccess: false,
  isAutoSelectFailure: undefined,
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  isSelectRequest: false,
  isSelectSuccess: false,
  isSelectFailure: undefined,
  isLinkRequest: false,
  isSelectAddressRequest: false,
  isSelectAddressSuccess: false,
  isSelectAddressFailure: undefined,
  isUpdateAccountRequest: false,
  isUpdateAccountSuccess: false,
  isUpdateAccountFailure: undefined,
  reqId: '',
  selected: undefined,
  list: [],
  details: [],
  calculatedPlacedOrders: [],
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case LIST_REQUEST: {
      return {
        ...state,
        isListRequest: true,
        isListBalancesSuccess: false,
        isListSuccess: false,
      };
    }

    case LIST_SUCCESS: {
      const payload = (action.payload || {
        wantBalances: false,
      }) as ListRequest;
      const accounts = (action.item || []) as Account[];

      if (payload?.wantBalances) {
        // we need to update the balance of the selected account details in case there are any changes
        let selected;
        if (state.selected?.id && accounts.length) {
          const found = accounts.find(
            (a) => a.id === (state.selected as Account).id,
          );
          if (found) {
            selected = {
              ...state.selected,
              ...found,
            };
          }
        }
        return {
          ...state,
          list: [...accounts] || [],
          isListRequest: false,
          isListSuccess: true,
          isListBalancesSuccess: true,
          isListFailure: undefined,
          selected,
        };
      }

      // IF there are no accounts, then there is nothing todo
      if (accounts.length <= 0) {
        return {
          ...state,
          list: [],
          isListRequest: false,
          isListSuccess: true,
          isListFailure: undefined,
        };
      }

      // we requested account information without the expensive balance external request.
      // we need to make sure that we don't overwrite balances, if we received that already.
      const persistBalance = accounts.map((a: Account) => {
        return {
          ...a,
          balance:
            a.balance || state?.list?.find((l) => l.id === a.id)?.balance,
        };
      });

      return {
        ...state,
        list: persistBalance || [],
        isListBalancesSuccess: false,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: false,
      };
    }
    case LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isListRequest: false,
        isListSuccess: false,
        isListBalancesSuccess: false,
        isListFailure: true,
      };
    }

    case AUTO_SELECT_REQUEST: {
      return {
        ...state,
        isAutoSelectRequest: true,
        isAutoSelectSuccess: false,
        isAutoSelectFailure: false,
      };
    }
    case AUTO_SELECT_SUCCESS: {
      return {
        ...state,
        isAutoSelectRequest: false,
        isAutoSelectSuccess: true,
        isAutoSelectFailure: false,
      };
    }
    case AUTO_SELECT_RESET: {
      return {
        ...state,
        isAutoSelectRequest: false,
        isAutoSelectSuccess: false,
        isAutoSelectFailure: false,
      };
    }
    case AUTO_SELECT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isAutoSelectRequest: false,
        isAutoSelectSuccess: false,
        isAutoSelectFailure: true,
      };
    }

    case READ_REQUEST: {
      const { payload } = action;
      const accountId = payload?.accountId;
      let details = state?.details || [];

      const listItem = state.details?.find((i) => i.id === accountId);
      if (listItem) {
        details = [...(state.details || []), listItem];
      }

      return {
        ...state,
        selected: state?.selected || listItem,
        details,
        isReadRequest: true,
      };
    }
    case READ_SUCCESS: {
      const { item: account } = action;
      const details = state?.details || [];
      const i = details.findIndex((c) => c.id === account.id);
      const newDetails =
        i >= 0
          ? [...details.slice(0, i), account, ...details.slice(i + 1)]
          : [...details, account];
      const updatedSelected =
        !state?.selected || state?.selected.id === account.id
          ? account
          : state.selected;
      const updatedListList =
        !state.selected || state.selected?.id === account.id
          ? state.list
          : updateItemInArray(account, state?.list);

      return {
        ...state,
        selected: updatedSelected,
        list: updatedListList,
        details: newDetails,
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: null,
      };
    }
    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: true,
      };
    }

    /**
     * Calculates a new temporary balance based on the amount from a placed order.
     * Reason: When demoing we want the give the impression of payment is received instantly.
     * The balance is not affected when an order is placed because it hasn't been processed.
     * Therefore, we simulate the balance change by optimistically hoping that the order will be
     * processed correctly. This is only done when orders are in placed state.
     */
    case ORDER_READ_SUCCESS: {
      const order = action.item as Order;
      const account = state?.selected;
      if (
        order?.meta?.state !== 'placed' ||
        typeof account?.balance !== 'string' ||
        order?.accountId !== account?.id ||
        state.calculatedPlacedOrders.find((orderId) => orderId === order.id)
      )
        return state;

      const newAccount: Account = {
        ...account,
        balance: calculateBalanceFromPendingOrder(order, account),
      };

      return {
        ...state,
        selected: newAccount,
        details: updateItemInArray(newAccount, state?.details || []),
        list: updateItemInArray(newAccount, state?.list || []),
        calculatedPlacedOrders: [...state.calculatedPlacedOrders, order.id],
      };
    }

    case SELECT_ACCOUNT: {
      const { account } = action;
      return {
        ...state,
        selected: account,
      };
    }

    case SET_REQUEST_ID: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPDATE_ACCOUNT_REQUEST: {
      return { ...state, isUpdateAccountRequest: true };
    }

    case UPDATE_ACCOUNT_SUCCESS: {
      const { id: accountId } = action.payload;
      const newList = state?.list?.map((acc) => {
        if (acc.id === accountId) {
          return { ...action.payload };
        }
        return acc;
      });

      return {
        ...state,
        list: newList,
        isUpdateAccountRequest: false,
        isUpdateAccountSuccess: true,
        isUpdateAccountFailure: false,
      };
    }
    case UPDATE_ACCOUNT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        isUpdateAccountRequest: false,
        isUpdateAccountSuccess: false,
        isUpdateAccountFailure: true,
      };
    }
    default:
      return state;
  }
};
