import {
  arbitrum as arbitrumMainnet,
  arbitrumSepolia as arbitrumSepoliaTestnet,
  avalanche as avalancheMainnet,
  avalancheFuji as avalancheFujiTestnet,
  gnosis as gnosisMainnet,
  gnosisChiado as gnosisChiadoTestnet,
  mainnet as ethereumMainnet,
  polygon as polygonMainnet,
  sepolia as ethereumSepolia,
} from 'viem/chains';
import {
  BlockExplorerUrlType,
  Chain,
  ChainId,
  ChainName,
  Network,
} from 'src/types/emoney/Chain';

export const chainSortOrder: ChainName[] = [
  'gnosis',
  'polygon',
  'arbitrum',
  'avalanche',
  'ethereum',
];

/**
 * Chain definitions for RainbowKit/wagmi
 */
export const polygonLocal: Chain = {
  id: 200,
  name: 'Polygon Local',
  network: 'local',
  nativeCurrency: {
    decimals: 18,
    name: 'Matic',
    symbol: 'tMatic',
  },
  rpcUrls: {
    default: {
      http: ['https://localhost:9545'],
    },
    public: {
      http: ['https://localhost:9545'],
    },
  },
  blockExplorers: {
    default: {
      name: 'EtherScan',
      url: '9545',
    },
  },
  iconUrl: '/assets/emoney/chains/polygon-bgfill-icon.svg',
  moneriumId: {
    chain: 'polygon',
    network: 'local',
  },
};

export const ethereumLocal: Chain = {
  id: 100,
  name: 'Ethereum Local',
  network: 'local',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: {
      http: ['https://localhost:8545'],
    },
    public: {
      http: ['https://localhost:8545'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  iconUrl: '/assets/emoney/chains/ethereum-bgfill-icon.svg',
  moneriumId: {
    chain: 'ethereum',
    network: 'local',
  },
};

export const polygonAmoy: Chain = {
  id: 80002,
  network: 'amoy',
  name: 'Polygon Amoy',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
    public: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Polyscan',
      url: 'https://amoy.polygonscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 3127388,
    },
  },
  testnet: true,
  iconUrl: '/assets/emoney/chains/polygon-bgfill-icon.svg',
  moneriumId: {
    chain: 'polygon',
    network: 'amoy',
  },
};
export const nobleFlorin: Chain = {
  // id: 80002,
  // network: 'florin',
  name: 'Noble Florin devnet',
  nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.florin.noble.xyz'],
    },
    public: {
      http: ['https://rpc.florin.noble.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Noble Explorer',
      url: 'https://explorer.florin.noble.xyz/florin',
    },
  },
  testnet: true,
  iconUrl: '/assets/emoney/chains/noble-bgfill-icon.svg',
  moneriumId: {
    chain: 'noble',
  },
};

export const arbitrum: Chain = {
  ...arbitrumMainnet,
  moneriumId: {
    chain: 'arbitrum',
    network: 'mainnet',
  },
};
export const arbitrumSepolia: Chain = {
  ...arbitrumSepoliaTestnet,
  iconUrl: '/assets/emoney/chains/arbitrum-icon.svg',
  moneriumId: {
    chain: 'arbitrum',
    network: 'sepolia',
  },
};

export const avalanche: Chain = {
  ...avalancheMainnet,
  moneriumId: {
    chain: 'avalanche',
    network: 'mainnet',
  },
};

export const avalancheFuji: Chain = {
  ...avalancheFujiTestnet,
  moneriumId: {
    chain: 'avalanche',
    network: 'fuji',
  },
};

export const mainnet: Chain = {
  ...ethereumMainnet,
  moneriumId: {
    chain: 'ethereum',
    network: 'mainnet',
  },
};

export const gnosis: Chain = {
  ...gnosisMainnet,
  iconUrl: '/assets/emoney/chains/gnosis-icon.svg',
  moneriumId: {
    chain: 'gnosis',
    network: 'mainnet',
  },
};

export const gnosisChiado: Chain = {
  ...gnosisChiadoTestnet,
  iconUrl: '/assets/emoney/chains/gnosis-icon.svg',
  moneriumId: {
    chain: 'gnosis',
    network: 'chiado',
  },
};
export const polygon: Chain = {
  ...polygonMainnet,
  moneriumId: {
    chain: 'polygon',
    network: 'mainnet',
  },
};

export const sepolia: Chain = {
  ...ethereumSepolia,
  name: 'Ethereum Sepolia',
  moneriumId: {
    chain: 'ethereum',
    network: 'sepolia',
  },
};

export const localhostChains = [ethereumLocal, polygonLocal];
export const testnetChains = [
  gnosisChiado,
  polygonAmoy,
  // arbitrumSepolia,
  // avalancheFuji,
  sepolia,
  // nobleFlorin,
];
export const productionChains = [gnosis, polygon, mainnet];
export const allChains: Chain[] = [
  ...testnetChains,
  ...productionChains,
  ...localhostChains,
];

export const splitMoneriumChainId = (
  moneriumChainId: ChainId,
): { chain: ChainName; network: Network } => {
  if (!moneriumChainId) throw new Error('Invalid chain id');
  const [chain, network] = moneriumChainId.split(':');
  return { chain, network } as { chain: ChainName; network: Network };
};

// as defined by Safe
export const chainShortName: Record<string, string> = {
  11155111: 'sep',
  137: 'matic',
  1: 'eth',
  100: 'gno',
};

export const getChainByMoneriumId = (
  moneriumChainId: ChainId | { chain: ChainName; network: Network },
): Chain | undefined => {
  let chainName = '';
  let networkName = '';

  if (typeof moneriumChainId === 'string') {
    const { chain, network } = splitMoneriumChainId(moneriumChainId);
    chainName = chain;
    networkName = network;
  } else {
    chainName = moneriumChainId.chain;
    networkName = moneriumChainId.network;
  }

  switch (chainName) {
    case 'arbitrum':
      if (networkName === 'mainnet') return arbitrum;
      if (networkName === 'sepolia') return arbitrumSepolia;
      return undefined;
    case 'avalanche':
      if (networkName === 'mainnet') return avalanche;
      if (networkName === 'fuji') return avalancheFuji;
      return undefined;
    case 'ethereum':
      if (networkName === 'mainnet') return mainnet;
      if (networkName === 'sepolia') return sepolia;
      if (networkName === 'local') return ethereumLocal;
      return undefined;
    case 'polygon':
      if (networkName === 'mainnet') return polygon;
      if (networkName === 'amoy') return polygonAmoy;
      if (networkName === 'local') return polygonLocal;
      return undefined;
    case 'gnosis':
      if (networkName === 'mainnet') return gnosis;
      if (networkName === 'chiado') return gnosisChiado;
      return undefined;
    default:
      return undefined;
  }
};

export const getExplorerUrl = (
  chain: Chain | 'noble' | undefined,
  type: BlockExplorerUrlType,
  hash: string,
) => {
  /**
   * TODO: Noble support
   * Redo this function to just accept a chain name and lookup the relevant blockExplorer.
   * Also relevant to this function (this has been broken for a while):
   * When doing cross-chain txs and then linking to the txHash, the second txHash is always the counterpart
   * but we are currently treating all txHash as the same chain.
   * */
  if (chain === 'noble') {
    const resourceType = type === 'address' ? 'account' : type;
    return `https://explorer.florin.noble.xyz/florin/${resourceType}/${hash}`;
  }
  if (type === 'address' && hash.includes('noble')) {
    return `https://explorer.florin.noble.xyz/florin/account/${hash}`;
  }
  if (type === 'tx' && !hash.startsWith('0x')) {
    return `https://explorer.florin.noble.xyz/florin/tx/${hash}`;
  }

  if (!chain) return 'UNKNOWN';
  return `${
    chain?.blockExplorers?.etherscan?.url || chain?.blockExplorers?.default?.url
  }/${type}/${hash}`;
};

export const getTokenFilteredByAddressExplorerUrl = (
  chain: Chain | undefined,
  address: string,
  tokenAddress: string,
) => {
  if (chain?.id === 10200) {
    return `${
      chain.blockExplorers?.etherscan?.url ||
      chain?.blockExplorers?.default?.url
    }/address/${address}?tab=token_transfers&token=${tokenAddress}`;
  }
  return `${
    chain?.blockExplorers?.etherscan?.url || chain?.blockExplorers?.default?.url
  }/token/${tokenAddress}?a=${address}`;
};

export const getDefaultChains = (): Chain[] => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    if (
      hostname === 'localhost' &&
      window.App.isProxy &&
      (window.App.apiUrl === 'https://sandbox.monerium.dev' ||
        window.App.apiUrl === 'https://staging.monerium.dev')
    ) {
      return testnetChains;
    }
    if (
      hostname === 'localhost' &&
      window.App.isProxy &&
      window.App.apiUrl === 'https://monerium.app'
    ) {
      return productionChains;
    }
    if (hostname === 'localhost') {
      return localhostChains;
    }
    if (hostname.includes('monerium.dev')) {
      return testnetChains;
    }
  }
  return productionChains;
};

export const getChainByMoneriumChain = (
  chain: ChainName,
): Chain | undefined => {
  /**
   * TODO: Noble support
   * Clean this up
   */
  if (chain === 'noble') {
    return nobleFlorin;
  }
  return getDefaultChains().find((c) => c.moneriumId?.chain === chain);
};
