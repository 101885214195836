import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import {
  ReduxStore,
  useAppDispatch,
  useAppSelector,
} from 'src/customer/store/configureStore';
import useProfile from '../../iam/Profile/hooks';
import actions from './actions';
import type { Account, UseAccountReturn } from './types';

const accountSelector = (state: ReduxStore) => state.emoney?.account;

export const selectedAccountSelector = createSelector(
  accountSelector,
  (account) => account?.selected || ({} as Account),
);

export const selectedAccountListSelector = createSelector(
  accountSelector,
  (account) => account?.list || [],
);

export const selectisListRequest = createSelector(
  accountSelector,
  (account) => account?.isListRequest,
);

const useAccount = (): UseAccountReturn => {
  const dispatch = useAppDispatch();
  const { profileId: currentProfileId } = useProfile();

  const getAccounts = useCallback(
    (profileId, wantBalances) => {
      return dispatch(actions.list({ profileId, wantBalances }));
    },
    [dispatch, currentProfileId],
  );

  const accounts = useAppSelector(selectedAccountListSelector, shallowEqual);

  const selectAccount = useCallback(
    (account) => dispatch(actions.selectAccount(account)),
    [dispatch],
  );

  const selectedAccount = useAppSelector(selectedAccountSelector, shallowEqual);
  const isListSuccess = useAppSelector(
    (state: ReduxStore) => !!state.emoney?.account?.isListSuccess,
    shallowEqual,
  );
  const isListBalancesSuccess = useAppSelector(
    (state: ReduxStore) => !!state.emoney?.account?.isListBalancesSuccess,
    shallowEqual,
  );
  const isUpdateAccountRequest = useAppSelector(
    (state: ReduxStore) => !!state.emoney?.account?.isUpdateAccountRequest,
    shallowEqual,
  );

  return {
    selectedAccount,
    accounts,
    isListRequest: useAppSelector(
      (state: ReduxStore) => !!state.emoney?.account?.isListRequest,
      shallowEqual,
    ),
    isListSuccess,
    isListBalancesSuccess,
    isListFailure: useAppSelector(
      (state: ReduxStore) => !!state.emoney?.account?.isListFailure,
      shallowEqual,
    ),
    isReadRequest: useAppSelector(
      (state: ReduxStore) => !!state.emoney?.account?.isReadRequest,
      shallowEqual,
    ),
    isReadSuccess: useAppSelector(
      (state: ReduxStore) => !!state.emoney?.account?.isReadSuccess,
      shallowEqual,
    ),
    isReadFailure: useAppSelector(
      (state: ReduxStore) => !!state.emoney?.account?.isReadFailure,
      shallowEqual,
    ),
    isUpdateAccountRequest,
    selectAccount,
    getAccounts,
    getAccount: useCallback(
      (accountId) => dispatch(actions.read({ accountId })),
      [dispatch],
    ),
    update: useCallback(
      (accountId, data) => dispatch(actions.update(accountId, data)),
      [dispatch],
    ),
  };
};

export default useAccount;
