import {
  Action as HAction,
  createBrowserHistory,
  Location as HLocation,
} from 'history';

export type Location = HLocation;
export type Action = HAction;

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history
export default process.env.BROWSER ? createBrowserHistory() : undefined;
