import { lightTheme, Theme } from '@rainbow-me/rainbowkit';

// eslint-disable-next-line import/prefer-default-export
export const getRainbowKitCustomTheme = (): Theme => {
  const brandColor = '#2c6ca7';
  const fontFamily = '"Roboto", sans-serif';
  const fontColor = '#242424';
  const errorColor = '#f1706c';
  const boxShadow = '0 5px 25px rgba(0,  0,  0,  0.06)';
  const borderRadius = '8px';
  const theme = lightTheme();

  // https://www.rainbowkit.com/docs/custom-theme
  const customTheme: Theme = {
    ...theme,

    colors: {
      ...theme.colors,
      accentColor: brandColor,
      connectButtonBackgroundError: errorColor,
      error: errorColor,
      modalText: fontColor,
    },
    fonts: {
      body: fontFamily,
    },
    shadows: {
      ...theme.shadows,
      connectButton: boxShadow,
      selectedOption: boxShadow,
      selectedWallet: boxShadow,
    },
    radii: {
      ...theme.radii,
      connectButton: borderRadius,
      menuButton: borderRadius,
    },
  };
  return customTheme;
};
