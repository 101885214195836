'use client';

import React from 'react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { config } from './config';
import Disclaimer from './RainbowKit/Disclaimer';
import { getRainbowKitCustomTheme } from './RainbowKit/theme';
import { WalletProvider } from './WalletProvider';

const queryClient = new QueryClient();

const WalletConnector = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiProvider config={config} reconnectOnMount>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          theme={getRainbowKitCustomTheme()}
          modalSize="wide"
          appInfo={{
            appName: 'Monerium',
            learnMoreUrl: 'https://monerium.com',
            disclaimer: Disclaimer,
          }}
        >
          <WalletProvider>{children}</WalletProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
export default WalletConnector;
