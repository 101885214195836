import rest from 'services/rest';
import { CurrencyCode } from 'src/types/emoney/Token';
import { SELECTED_ACCOUNT_STORAGE_KEY } from 'customer/constants';
import type { Address } from '../Address/types';
// import tempMockData from './tempMockData';
import type { Account, ExchangeRate, ListRequest, ReadRequest } from './types';

interface Storage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

interface Options {
  address?: string;
  key?: string;
  storage?: Storage;
}

/**
 * concat key and address that is used to store valued in storage
 * @param options key and address
 * @returns combined key for storage
 */
const getLastSelectedKey = (options: Options) => {
  if (typeof options.address !== 'string')
    throw Error(`address must be string,got: ${typeof options.address}`);
  if (typeof options.key !== 'string')
    throw Error(`key must be string,got: ${typeof options.key}`);
  if (!options.address) return options.key;
  return (options.key + options.address).toLowerCase();
};

export default {
  list: async (data: ListRequest): Promise<Account[]> => {
    let profileId = data?.profileId;

    // todo: should we remove this edge case. Why are we supporting this call without profile ID, it's very inefficient.
    try {
      if (!profileId) {
        profileId = await rest.get('/api/iam/profiles').then((p) => p[0].id);
      }
    } catch (error) {
      console.error(error);
      return [];
    }
    const wantBalances = !!data?.wantBalances;
    return rest.get(
      `/api/emoney/profiles/${profileId}/accounts?balances=${wantBalances}`,
    );
  },

  read: (data: ReadRequest): Promise<Account> => {
    return rest.get(`/api/emoney/accounts/${data?.accountId}`);
  },

  create: (profileId: string, data: Partial<Address>): Promise<Address> =>
    rest.post(
      `/api/emoney/profiles/${profileId}/addresses`,
      JSON.stringify(data),
    ),

  update: (id: string, data: Address): Promise<Address> =>
    rest.put(`/api/emoney/addresses/${id}`, JSON.stringify(data)),

  // todo: the payload for isVisible should have been {meta: {isVisible:boolean}} for consistency.
  updateAccount: (
    accountId: string,
    data: Partial<Account>,
  ): Promise<Account> => rest.patch(`/api/emoney/accounts/${accountId}`, data),

  getExchangeRate: (
    fromCurrency: CurrencyCode,
    toCurrency: CurrencyCode,
    amount: string,
  ): Promise<ExchangeRate> => {
    const data = {
      from: fromCurrency,
      to: toCurrency,
      amount,
    };
    return rest.post(`/api/convert`, data);
  },

  getLastSelected: (options?: Options) => {
    const o: Options = {
      address: '',
      key: SELECTED_ACCOUNT_STORAGE_KEY,
      storage: localStorage,
      ...(options || {}),
    };
    if (!o.storage) throw Error('storage missing');
    const accountId = o.storage.getItem(getLastSelectedKey(o));
    if (accountId) return accountId;
    return undefined;
  },
  setLastSelected: async (accountId: string, options?: Options) => {
    const o: Options = {
      address: '',
      key: SELECTED_ACCOUNT_STORAGE_KEY,
      storage: localStorage,
      ...options,
    };
    if (!o.storage) throw Error('storage missing');
    return o.storage.setItem(getLastSelectedKey(o), accountId);
  },

  delLastSelected: async (options?: Options) => {
    const o: Options = {
      address: '',
      key: SELECTED_ACCOUNT_STORAGE_KEY,
      storage: localStorage,
      ...(options || {}),
    };
    if (!o.storage) throw Error('storage missing');
    return o.storage.removeItem(getLastSelectedKey(o));
  },
};
