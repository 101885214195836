import { shallowEqual, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import type { ReduxStore } from 'customer/types';
import { Profile } from './types';

interface ProfileHook {
  profile?: Profile;
  profileId: string;
}

const selectProfile = (state: ReduxStore) => state.iam?.profile;

const profileSelector = createSelector(
  selectProfile,
  (profile) => profile?.selected,
);

const useProfile = (): ProfileHook => {
  const selectedProfile = useSelector(profileSelector, shallowEqual);
  return {
    profile: selectedProfile,
    profileId: selectedProfile?.id || '',
  };
};

export default useProfile;
